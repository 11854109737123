import { DrawerSelectEvent } from "@progress/kendo-vue-layout";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import router from "@/router";
import { iconMap } from "@/style/iconMap";

import { useEconomicEntities } from "./useEconomicEntities";
import { useProjects } from "./useProjects";
// eslint-disable-next-line no-restricted-imports
import { Entity, usePermissions } from "../../app/_resources/usePermissions";

export type DrawerItem = {
  text: string;
  icon: string;
  data: {
    show: boolean;
    name: string;
    active: boolean;
    header?: string;
    separator?: string;
    badge?: "success" | "gray";
  };
};

const expanded = ref(false);
const { canRead } = usePermissions();

export const useDrawer = () => {
  const { t, d } = useI18n();

  const { currentProject } = useProjects();
  const { acceptedEstimate } = useEconomicEntities({
    urlParams: {
      projectId: router.currentRoute.value.params.projectId as string,
    },
    enabled: false,
  });

  const currentRoute = computed(() => router.currentRoute.value);
  const items = computed(() => {
    let toRet = [];
    if (
      currentRoute.value.path.includes("/projects/") &&
      currentRoute.value.path !== "/projects/create"
    ) {
      toRet = projectItems.value;
    } else {
      toRet = defaultItems.value;
    }
    return toRet.filter((el) => el.data.show);
  });
  const baseRoutes = ["home", "projectDashboard", "backoffice"];
  // @ts-ignore
  const checkPrevRoutes = (currentRoute: any, routeName: string) => {
    if (routeName === currentRoute.name) {
      return true;
    } else if (currentRoute.meta.prevRoute && !baseRoutes.includes(routeName)) {
      const prevRoute = useRouter()
        .getRoutes()
        .find((el) => el.name === currentRoute.meta.prevRoute);
      return checkPrevRoutes(prevRoute, routeName);
    }
    return false;
  };

  const mappedItems = computed(() => {
    return items.value.map((item) => {
      if (item.data) {
        return {
          ...item,
          selected: checkPrevRoutes(currentRoute.value, item.data.name),
        };
      } else {
        return { ...item };
      }
    });
  });
  const drawerTitle = computed(() => {
    if (
      currentRoute.value.path.includes("/projects/") &&
      currentRoute.value.path !== "/projects/create"
    ) {
      return currentProject.value?.main.title;
    } else {
      return null;
    }
  });
  const drawerDescription = computed(() => {
    if (
      currentRoute.value.path.includes("/projects/") &&
      currentRoute.value.path !== "/projects/create"
    ) {
      return `Dal ${d(currentProject.value?.main.dateStart)} al ${d(
        currentProject.value?.main.dateEnd
      )}`;
    } else {
      return null;
    }
  });
  const drawerBackMessage = computed(() => {
    if (
      currentRoute.value.path.includes("/projects/") &&
      currentRoute.value.path !== "/projects/create"
    ) {
      return t("pages.projectDashboard.backMessage");
    } else if (currentRoute.value.path.includes("/backoffice/")) {
      return t("pages.backofficeDashboard.backMessage");
    } else {
      return null;
    }
  });

  const defaultItems = ref([
    {
      text: t("pages.home.name"),
      icon: iconMap.context.home,
      data: {
        show: true,
        name: "home",
        active: true,
      },
    },
    {
      text: t("pages.projects.name"),
      icon: iconMap.context.projects,
      data: {
        show: true,
        name: "projects",
        active: true,
      },
    },
    {
      text: t("pages.orders.name"),
      icon: iconMap.context.orders,
      data: {
        show: true,
        name: "orders",
        active: true,
      },
    },
    {
      text: t("pages.backofficeDashboard.title"),
      icon: iconMap.context.backoffice,
      data: {
        show: true,
        name: "backofficeDashboard",
        active: true,
      },
    },
    {
      text: t("entities.customer.name", 2),
      icon: iconMap.generic,
      data: {
        show: canRead(Entity.customer),
        name: "customers",
        active: true,
        separator: t("drawer.anagraphics"),
      },
    },
    {
      text: t("entities.supplier.name", 2),
      icon: iconMap.generic,
      data: {
        show: canRead(Entity.supplier),
        name: "suppliers",
        active: true,
      },
    },
    {
      text: t("entities.company.name", 2),
      icon: iconMap.generic,
      data: {
        show: canRead(Entity.company),
        name: "companies",
        active: true,
      },
    },
    {
      text: t("pages.portalUsers.title"),
      icon: iconMap.context.portalUsers,
      data: {
        show: canRead(Entity.portalUser),
        name: "portalUsers",
        active: true,
        separator: t("drawer.usersManagement"),
      },
    },
    {
      text: t("entities.portalRole.name", 2),
      icon: iconMap.context.portalRoles,
      data: {
        show: canRead(Entity.portalRole),
        name: "portalRoles",
        active: true,
      },
    },
    {
      text: t("entities.projectRole.name", 2),
      icon: iconMap.context.projectRoles,
      data: {
        show: canRead(Entity.projectRole),
        name: "projectRoles",
        active: true,
      },
    },
  ] as DrawerItem[]);

  const projectItems = computed(
    () =>
      [
        {
          text: t("pages.home.name"),
          icon: iconMap.navigation.back,
          data: {
            show: true,
            name: "projects",
            header: "projects",
          },
        },
        {
          text: t("pages.projectDashboard.name"),
          icon: iconMap.context.dashboard,
          data: {
            show: true,
            name: "projectDashboard",
            active: true,
          },
        },
        {
          text: t("pages.economicManagement.name"),
          icon: iconMap.context.economicManagement,
          data: {
            show: true,
            name: "economicManagement",
            active: true,
            badge: acceptedEstimate.value ? "success" : "gray",
          },
        },
        {
          text: t("pages.participantsCategories.name"),
          icon: iconMap.context.participantsCategories,
          data: {
            show: true,
            name: "participantsCategories",
            active: true,
          },
        },
        {
          text: t("entities.sponsorBusiness.name", 2),
          icon: iconMap.context.sponsorBusiness,
          data: {
            show: true,
            name: "sponsorBusinesses",
            active: true,
            separator: t("drawer.project.sponsor"),
          },
        },
        {
          text: t("pages.sponsorServices.name"),
          icon: iconMap.context.sponsorServices,
          data: {
            show: true,
            name: "sponsorServices",
            active: true,
          },
        },
        {
          text: t("pages.sponsorKits.name"),
          icon: iconMap.context.sponsorKits,
          data: {
            show: true,
            name: "sponsorKits",
            active: true,
          },
        },
        {
          text: t("entities.class.name", 2),
          icon: iconMap.context.classes,
          data: {
            show: true,
            name: "classes",
            separator: t("drawer.project.planning"),
            active: true,
          },
        },
      ] as DrawerItem[]
  );

  const selectItem = (event: DrawerSelectEvent) => {
    const selectedItem = items.value[event.itemIndex];

    if (selectedItem?.data?.active) {
      if (event.itemIndex !== undefined) {
        if (selectedItem.data.name) {
          router.push({
            name: selectedItem.data.name,
            params: currentRoute.value.params,
          });
        }
      }
    }
  };

  const toggleDrawer = () => (expanded.value = !expanded.value);

  return {
    currentRoute,
    expanded,
    mappedItems,
    selectItem,
    toggleDrawer,
    drawerTitle,
    drawerDescription,
    drawerBackMessage,
  };
};
